import { GetFeedParams, GetFeedResponse } from '~/api/feed/feedApiTypes';
import { provider } from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { ApiResponse, RejectedRequest } from '~/api/provider/providerTypes';

const namespace = Symbol('namespace');

export const feedApi = {
  [namespace]: '/v1/feed',

  async get(data: GetFeedParams) {
    try {
      const response = await provider.post<
      ApiResponse<GetFeedResponse, RejectedRequest<void>>
      >(
        `${this[namespace]}/main-v2`,
        {
          ...data,
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
