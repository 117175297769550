import { createAsyncThunk } from '@reduxjs/toolkit';

import { notificationsApi } from '~/api/account/notificationsApi';
import {
  EmailDigestUnsubscribeParams,
  GetNotificationsParams,
  NotificationChannelsEnum,
} from '~/api/account/notificationsApiTypes';
import {
  notificationsActions,
  notificationsSliceName,
} from '~/feature/notification/notifications.slice';
import { userActions } from '~/feature/user/user.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const markAsRead = createAsyncThunk<
string[],
void,
{ rejectValue: { error: string }; state: RootState }
>(
  `${notificationsSliceName}/markAsRead`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      await notificationsApi.markAsRead();
      thunkAPI.dispatch(userActions.markAsReadNotifications());
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getNotifications = createAsyncThunk<
void,
GetNotificationsParams,
{ rejectValue: { error: string }; }
>(
  `${notificationsSliceName}/getNotifications`,
  async (
    data,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(notificationsActions.changeNotificationsLoading(true));
    try {
      const result = await notificationsApi.getNotifications(data);
      if (result && 'data' in result) {
        thunkAPI.dispatch(notificationsActions.changeNotifications(result.data));
        thunkAPI.dispatch(notificationsActions.changePagination({
          total: result.total,
          page: result.currentPage,
          perPage: result.perPage,
        }));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(notificationsActions.changeNotificationsLoading(false));
    }
  },
);

export const getMoreNotifications = createAsyncThunk<
void,
GetNotificationsParams,
{ rejectValue: { error: string }; }
>(
  `${notificationsSliceName}/getMoreNotifications`,
  async (
    data,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(notificationsActions.changeNotificationsLoading(true));
    try {
      const result = await notificationsApi.getNotifications(data);
      if (result && 'data' in result) {
        thunkAPI.dispatch(notificationsActions.loadMoreNotifications(result.data));
        thunkAPI.dispatch(notificationsActions.changePagination({
          total: result.total,
          page: result.currentPage,
          perPage: result.perPage,
        }));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(notificationsActions.changeNotificationsLoading(false));
    }
  },
);

export const getNotificationCategories = createAsyncThunk<
void,
void,
{ rejectValue: { error: string }; }
>(
  `${notificationsSliceName}/getNotificationCategories`,
  async (
    _,
    thunkAPI,
  ) => {
    const result = await notificationsApi.getNotificationCategories();

    if (result && 'data' in result) {
      thunkAPI.dispatch(notificationsActions.changeGroups(result.data));
    }
  },
);

export const getNotificationsSettings = createAsyncThunk<
void,
void,
{ rejectValue: { error: string }; }
>(
  `${notificationsSliceName}/getNotificationsSettings`,
  async (
    _,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(notificationsActions.setIsGetNotificationSettings(true));
      const result = await notificationsApi.getNotificationsSettings();

      if (result && 'data' in result) {
        thunkAPI.dispatch(notificationsActions.changeSettings(result.data));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(notificationsActions.setIsGetNotificationSettings(false));
    }
  },
);

export const saveNotificationsSettings = createAsyncThunk<
void,
void,
{
  state: RootState;
  rejectValue: { error: string };
}
>(
  `${notificationsSliceName}/saveNotificationsSettings`,
  async (
    _,
    thunkAPI,
  ) => {
    try {
      const { settings: data } = thunkAPI.getState().notifications;
      await notificationsApi.postNotificationsSettings({
        settings: data.map((setting) => ({
          group: setting.group.alias,
          channels: setting.channels,
        })),
      });
      thunkAPI.dispatch(getNotificationsSettings());
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changeAndSaveSettings = createAsyncThunk<
void,
{ group: string; value: boolean; channel: NotificationChannelsEnum }
>(
  `${notificationsSliceName}/changeAndSaveSettings`,
  async (
    payload,
    thunkAPI,
  ) => {
    try {
      await thunkAPI.dispatch(notificationsActions.updateNotificationSettings(payload));
      await thunkAPI.dispatch(saveNotificationsSettings());
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const emailDigestUnsubscribe = createAsyncThunk<void, EmailDigestUnsubscribeParams>(
  `${notificationsSliceName}/emailDigestUnsubscribe`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { Modal } = await import('antd');

      await notificationsApi.emailDigestUnsubscribe(data);
      Modal.success({
        title: 'Вы отписались от email-рассылки',
        okText: 'Закрыть',
        maskClosable: true,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const emailDiscountUnsubscribe = createAsyncThunk<void, EmailDigestUnsubscribeParams>(
  `${notificationsSliceName}/emailDiscountUnsubscribe`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { Modal } = await import('antd');

      await notificationsApi.emailDiscountUnsubscribe(data);
      Modal.success({
        title: 'Вы отписались от email-рассылки',
        okText: 'Закрыть',
        maskClosable: true,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
