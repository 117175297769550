import Router from 'next/router';
import qs from 'query-string';

interface SetQueriesToUrlProps {
  queries: {
    [key: string]: string
  };
}

export const setQueriesToUrl = async (
  { queries }: SetQueriesToUrlProps,
) => {
  const parsedUrl = qs.parseUrl(String(window.location));

  Object.entries(queries).forEach(([key, value]) => {
    parsedUrl.query[key] = value;
  });

  await Router.replace(
    qs.stringifyUrl(parsedUrl),
    undefined,
    { shallow: true },
  );
};
