import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { GetServerSidePropsContext } from 'next';
import Router from 'next/router';
import nookies from 'nookies';
import qs from 'query-string';

import { WithToken } from '~/api/auth/authApiTypes';
import { bookApi } from '~/api/book/bookApi';
import { Book, BookSortingEnum } from '~/api/book/bookApiTypes';
import { feedApi } from '~/api/feed/feedApi';
import {
  FeedGenreType, FeedSlider, FeedTabEnum, GetFeedResponse,
} from '~/api/feed/feedApiTypes';
import { baseFrontServerUrl } from '~/api/provider/provider.config';
import { ApiResponse } from '~/api/provider/providerTypes';
import {
  feedSelector,
  genresCheckboxForApiSelector,
  isAllFavoriteGenresIsActiveSelector,
} from '~/atomic/page/index/feed.selector';
import {
  defaultFeedGenres,
  FavoriteGenreFromCookieType,
  feedActions,
  feedSliceName,
} from '~/atomic/page/index/feed.slice';
import { confirmRegistration } from '~/feature/authorization/auth.data';
import {
  catalogFilterGenresForApiSelector,
  catalogFilterSelector,
} from '~/feature/catalog/filter/catalogFilter.selector';
import { isMobileSizeSelector, isTabletSizeSelector } from '~/feature/media/windowSize.selector';
import {
  emailDigestUnsubscribe,
  emailDiscountUnsubscribe,
} from '~/feature/notification/notifications.data';
import { paginationSelector } from '~/feature/pagination/pagination.selector';
import { paginationActions } from '~/feature/pagination/pagination.slice';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { emailConfirmation } from '~/feature/user/user.data';
import { setQueriesToUrl } from '~/feature/utils/setQueriesToUrl';
import { environments } from '~/lib/const';
import {
  cookieActiveFeedGenresKey,
  oldCookieActiveFeedGenresKey,
} from '~/pages/api/favoriteGenres/setGenres';
import { RootState } from '~/store';

const NewFeedPerPage = 16;

export const loveFeedGenres = defaultFeedGenres.map((genre) => {
  if (genre.name === 'Любовный роман'
    || genre.name === 'Эротика'
    || genre.name === 'Молодежная проза') {
    return {
      ...genre,
      isActive: true,
      childs: genre.childs.map((childGenre) => ({ ...childGenre, isActive: true })),
    };
  }
  return {
    ...genre,
    isActive: false,
    childs: genre.childs.map((childGenre) => ({ ...childGenre, isActive: false })),
  };
});

export const fantasyFeedGenres = defaultFeedGenres.map((genre) => {
  if (genre.name === 'Любовный роман'
    || genre.name === 'Эротика'
    || genre.name === 'Молодежная проза') {
    return {
      ...genre,
      isActive: false,
      childs: genre.childs.map((childGenre) => ({ ...childGenre, isActive: false })),
    };
  }
  return {
    ...genre,
    isActive: true,
    childs: genre.childs.map((childGenre) => ({ ...childGenre, isActive: true })),
  };
});

export const saveFavoriteGenresInCookie = createAsyncThunk<
void, Array<any> | undefined,
{ rejectValue: { error: string }, state: RootState }
>(
  `${feedSliceName}/saveGenresInCookie`,
  async (genresFromProps, thunkAPI) => {
    const { genres: genresFromStore } = feedSelector(thunkAPI.getState());
    try {
      const genreSlugs = genresFromProps ?? [];

      if (!genresFromProps) {
        genresFromStore.forEach((genre, index) => {
          genreSlugs[index] = {
            i: genre.isActive ? '1' : '0',
            c: genre.childs.map((child) => (child.isActive ? '1' : '0')).join(''),
          };
        });
      }
      await fetch(
        `${baseFrontServerUrl}/api/favoriteGenres/setGenres`,
        {
          method: 'POST',
          body: JSON.stringify({ genres: genreSlugs }),
        },
      );
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getGenresFromCookie = async (ctx?: GetServerSidePropsContext): Promise<string> => {
  if (environments.isClient) {
    const response = await fetch(`${baseFrontServerUrl}/api/favoriteGenres/getGenres`);
    return (await response?.json())?.genres ?? '';
  }

  let genres = (nookies.get(ctx))?.[cookieActiveFeedGenresKey] ?? '' as string;

  if (!genres.length) {
    genres = (nookies.get(ctx))?.[oldCookieActiveFeedGenresKey] ?? '' as string;
  }

  return genres;
};

export const getGenresFromCookieAndSave = createAsyncThunk<
FavoriteGenreFromCookieType[], GetServerSidePropsContext | undefined,
{ rejectValue: { error: string }, state: RootState }
>(
  `${feedSliceName}/getGenresFromCookie`,
  async (ctx, thunkAPI) => {
    const { genres } = feedSelector(thunkAPI.getState());
    try {
      let favoriteGenresFromCookie = [] as any;
      const favoriteGenresFromCookieJSON = await getGenresFromCookie(ctx);

      if (favoriteGenresFromCookieJSON) {
        favoriteGenresFromCookie = JSON.parse(favoriteGenresFromCookieJSON);
      }

      if (favoriteGenresFromCookie
        && Array.isArray(favoriteGenresFromCookie)
        && favoriteGenresFromCookie.length > 0) {
        if (favoriteGenresFromCookie.length === 6) {
          favoriteGenresFromCookie = [
            favoriteGenresFromCookie[0],
            favoriteGenresFromCookie[1],
            { i: '1', c: '111111' },
            favoriteGenresFromCookie[2],
            favoriteGenresFromCookie[3],
            favoriteGenresFromCookie[4],
            { i: '1', c: '' },
            favoriteGenresFromCookie[5],
          ];
        }

        const savedGenres = genres?.map((genre, index) => {
          return {
            ...genre,
            isActive: favoriteGenresFromCookie[index].i === '1',
            childs: genre.childs.map((childGenre, childIndex) => {
              const isActive = favoriteGenresFromCookie[index].c[childIndex] === '1';

              return {
                ...childGenre,
                isActive,
              };
            }),
          };
        });

        thunkAPI.dispatch(changeFeedGenres({ genres: savedGenres }));
        thunkAPI.dispatch(feedActions.changeGenresFromCookie(favoriteGenresFromCookie));
        thunkAPI.dispatch(feedActions.setIsShowEroticGenreBLock(false));
      }

      return favoriteGenresFromCookie;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getFeed = createAsyncThunk<
FeedSlider[], void,
{ rejectValue: { error: string }, state: RootState }
>(
  `${feedSliceName}/getFeed`,
  async (_, thunkAPI) => {
    const genresCheckboxForApi = genresCheckboxForApiSelector(thunkAPI.getState());
    thunkAPI.dispatch(feedActions.setIsGedFeedLoading(true));

    try {
      const result = await feedApi.get({
        genres: genresCheckboxForApi,
        bestsellersPage: 1,
        bestsellersPerPage: 12,
      });

      if (result && 'data' in result) {
        await thunkAPI.dispatch(feedActions.addSliders(result.data));
        thunkAPI.dispatch(feedActions.setIsGedFeedLoading(false));

        return result.data;
      }
      thunkAPI.dispatch(feedActions.setIsGedFeedLoading(false));
    } catch (error) {
      thunkAPI.dispatch(feedActions.setIsGedFeedLoading(false));
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

interface GetNewFeedBooksProps extends WithToken {
  state: RootState;
  page?: number;
}

const getNewFeedBooks = async ({
  page = 1, state, ...data
}: GetNewFeedBooksProps) => {
  const { feedTab, recommendationSortingType } = feedSelector(state);
  const genresCheckboxForApi = genresCheckboxForApiSelector(state);
  const isAllFavoriteGenresIsActive = isAllFavoriteGenresIsActiveSelector(state);
  const favoriteGenres = isAllFavoriteGenresIsActive ? undefined : JSON.stringify({ genres: genresCheckboxForApi });
  const token = data && data?.token ? data.token : undefined;

  let books = [] as Array<Book>;
  if (feedTab === 'popularity') {
    const response = await bookApi.getBookList({
      page,
      perPage: NewFeedPerPage,
      token,
      favoriteGenres,
    });
    books = response.data;
  }
  if (feedTab === 'bestseller') {
    const response = await bookApi.getBookList({
      sortingType: BookSortingEnum.Bestseller,
      page,
      perPage: NewFeedPerPage,
      token,
      favoriteGenres,
    });
    books = response.data;
  }
  if (feedTab === 'discount') {
    const response = await bookApi.getBookList({
      minDiscountPercent: 1,
      page,
      perPage: NewFeedPerPage,
      token,
      favoriteGenres,
      sortingType: BookSortingEnum.Rand,
    });
    books = response.data;
  }
  if (feedTab === 'exclusive') {
    const response = await bookApi.getBookList({
      exclusive: true,
      page,
      perPage: NewFeedPerPage,
      sortingType: BookSortingEnum.Rand,
      token,
      favoriteGenres,
    });
    books = response.data;
  }
  if (feedTab === 'recommended') {
    const response = await bookApi.getBookList({
      sortingType: recommendationSortingType,
      page,
      perPage: NewFeedPerPage,
      withRecommendations: true,
      token,
      favoriteGenres,
    });
    books = response.data;
  }
  if (feedTab === 'news') {
    const response = await bookApi.getBookList({
      sortingType: BookSortingEnum.Newest,
      page,
      perPage: NewFeedPerPage,
      token,
      favoriteGenres,
    });
    books = response.data;
  }
  if (feedTab === FeedTabEnum.Listen) {
    const response = await bookApi.getBookList({
      page,
      perPage: NewFeedPerPage,
      withAudiobook: true,
      token,
      favoriteGenres,
    });
    books = response.data;
  }
  if (feedTab === FeedTabEnum.Abonnement) {
    const additionalGenres = catalogFilterGenresForApiSelector(state);
    const {
      completeStatus, isOnlyFree, isCanDownload, bookType, tagSlug, sortingType,
    } = catalogFilterSelector(state);

    const response = await bookApi.getBookList({
      page,
      perPage: NewFeedPerPage,
      additionalGenres,
      statusComplete: completeStatus,
      free: isOnlyFree ? true : undefined,
      download: isCanDownload ? true : undefined,
      type: bookType,
      tag: tagSlug === '' ? undefined : tagSlug,
      sortingType,
      withAbonnement: true,
      token,
      favoriteGenres: additionalGenres ? undefined : favoriteGenres,
    });
    books = response.data;
  }

  return books;
};

export const getNewFeed = createAsyncThunk<
void, WithToken | void,
{ rejectValue: { error: string }, state: RootState }
>(
  `${feedSliceName}/getNewFeed`,
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(feedActions.setIsLoadFeedBooks(true));
      thunkAPI.dispatch(paginationActions.setPage({ page: 1 }));

      const books = await getNewFeedBooks({ state: thunkAPI.getState(), ...data });

      if (books.length < NewFeedPerPage) {
        thunkAPI.dispatch(feedActions.setIsDisableShowMore(true));
      } else {
        thunkAPI.dispatch(feedActions.setIsDisableShowMore(false));
      }

      thunkAPI.dispatch(feedActions.changeFeedBooks(books));
    } catch (error) {
      console.log('error', error);
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(feedActions.setIsLoadFeedBooks(false));
    }
  },
);

export const loadMoreFeedBooks = createAsyncThunk<
void, WithToken | void,
{ rejectValue: { error: string }, state: RootState }
>(
  `${feedSliceName}/loadMoreFeedBooks`,
  async (data, thunkAPI) => {
    try {
      const { page } = paginationSelector(thunkAPI.getState());
      const { feedBooks } = feedSelector(thunkAPI.getState());
      thunkAPI.dispatch(feedActions.setIsLoadMoreFeedBooksLoading(true));

      const books = await getNewFeedBooks({
        page, state: thunkAPI.getState(), ...data,
      });

      if (books.length < NewFeedPerPage) {
        thunkAPI.dispatch(feedActions.setIsDisableShowMore(true));
      }

      thunkAPI.dispatch(feedActions.changeFeedBooks([...feedBooks, ...books]));
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(feedActions.setIsLoadMoreFeedBooksLoading(false));
    }
  },
);

const getKeyFromFavoriteGenresFromCookie = (genres: FavoriteGenreFromCookieType[], isMobileSize: boolean) => {
  let result = '';

  genres?.forEach((genre) => {
    result += JSON.stringify(genre);
  });

  if (isMobileSize) {
    result += 'm=t';
  }

  return result;
};

export const getFeedFromCache = createAsyncThunk<
FeedSlider[], WithToken & {
  redisClient: any
},
{ rejectValue: { error: string }, state: RootState }
>(
  `${feedSliceName}/getFeedFromCache`,
  async ({ redisClient, token }, thunkAPI) => {
    thunkAPI.dispatch(feedActions.setIsGedFeedLoading(true));
    const genresCheckboxForApi = genresCheckboxForApiSelector(thunkAPI.getState());
    const isLoggedIn = isLoggedInSelector(thunkAPI.getState());
    const isTabletSize = isTabletSizeSelector(thunkAPI.getState());
    const isMobileSize = isMobileSizeSelector(thunkAPI.getState());
    const { genresFromCookie: genres } = feedSelector(thunkAPI.getState());

    try {
      const key = getKeyFromFavoriteGenresFromCookie(genres, isMobileSize);

      const cache = JSON.parse(await redisClient.get(key));
      let result: ApiResponse<GetFeedResponse, undefined>;

      if (cache && !isLoggedIn) {
        result = cache;
        console.log(`from cache main page with key=${key}`);
      } else {
        result = await feedApi.get({
          genres: genresCheckboxForApi,
          bestsellersPage: 1,
          bestsellersPerPage: (isTabletSize || isMobileSize) ? 6 : 8,
          token,
        });

        if (!isLoggedIn) {
          redisClient.set(key, JSON.stringify(result), 'EX', 60 * 15);
        }
      }

      if (result && 'data' in result) {
        await thunkAPI.dispatch(feedActions.addSliders(result.data));
        thunkAPI.dispatch(feedActions.setIsGedFeedLoading(false));

        return result.data;
      }
      thunkAPI.dispatch(feedActions.setIsGedFeedLoading(false));
    } catch (error) {
      thunkAPI.dispatch(feedActions.setIsGedFeedLoading(false));
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const loadDefaultFavoriteGenresInCookie = createAsyncThunk(
  `${feedSliceName}/loadDefaultFavoriteGenresInCookie`,
  async (_, thunkAPI) => {
    const favoriteGenresFromCookie = await getGenresFromCookie();

    if (!favoriteGenresFromCookie) {
      thunkAPI.dispatch(saveFavoriteGenresInCookie(undefined));
    }
  },
);

export const loadMoreBestsellers = createAsyncThunk<
void, void,
{ rejectValue: { error: string }, state: RootState }
>(
  `${feedSliceName}/getFeed`,
  async (_, thunkAPI) => {
    const { sliders, loadMorePage } = feedSelector(thunkAPI.getState());
    thunkAPI.dispatch(feedActions.setIsLoadMoreBestsellersLoading(true));
    try {
      const genresCheckboxForApi = genresCheckboxForApiSelector(thunkAPI.getState());
      const isTabletSize = isTabletSizeSelector(thunkAPI.getState());
      const isMobileSize = isMobileSizeSelector(thunkAPI.getState());
      const bestsellersPerPage = (isTabletSize || isMobileSize) ? 6 : 8;
      const result = await feedApi.get({
        genres: genresCheckboxForApi,
        bestsellersPage: loadMorePage + 1,
        bestsellersPerPage,
      });

      if (result && 'data' in result) {
        await thunkAPI.dispatch(feedActions.addSliders(
          sliders
            .map((slider) => {
              if (slider.keyword.type === 'bestseller') {
                const bestsellersBooks = result.data.find(
                  (slider) => slider.keyword.type === 'bestseller',
                ).books;

                if (bestsellersBooks.length < bestsellersPerPage) {
                  thunkAPI.dispatch(feedActions.changeLoadMorePage(5));
                }

                return {
                  ...slider,
                  books: [
                    ...slider.books,
                    ...bestsellersBooks,
                  ],
                };
              }

              return slider;
            }),
        ));
      }
      thunkAPI.dispatch(feedActions.setIsLoadMoreBestsellersLoading(false));
    } catch (error) {
      thunkAPI.dispatch(feedActions.setIsLoadMoreBestsellersLoading(false));
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changeFeedMainGenres = createAsyncThunk<
void, FeedGenreType,
{ state: RootState }
>(
  `${feedSliceName}/changeFeedMainGenres`,
  (targetGenre, thunkAPI) => {
    const { genres } = feedSelector(thunkAPI.getState());

    thunkAPI.dispatch(changeFeedGenres({
      genres: genres.map((genre) => {
        if (genre.name === targetGenre.name) {
          const newGenre = {
            ...targetGenre,
            childs: targetGenre.childs
              .map((childGenre) => ({ ...childGenre, isActive: !genre.isActive })),
            isActive: !targetGenre.isActive,
          };

          return newGenre;
        }

        return genre;
      }),
      targetGenre,
    }));
  },
);

const saveNewFeedTabInRouter = async (newFeedTab: string) => {
  await setQueriesToUrl({ queries: { feedTab: newFeedTab } });
};

export const changeNewFeedTab = createAsyncThunk<
void, FeedTabEnum,
{ state: RootState }
>(
  `${feedSliceName}/changeFeedMainGenres`,
  async (newFeedTab, thunkAPI) => {
    thunkAPI.dispatch(feedActions.changeFeedTab(newFeedTab));
    await saveNewFeedTabInRouter(newFeedTab);
  },
);

export const saveFeedTabFromUrl = createAsyncThunk<
void, GetServerSidePropsContext,
{ state: RootState }
>(
  `${feedSliceName}/saveFeedTabFromUrl`,
  async (ctx, thunkAPI) => {
    try {
      const feedTabQuery = await ctx.query.feedTab as string;
      if (feedTabQuery && Object.values(FeedTabEnum).some((key) => key === feedTabQuery)) {
        thunkAPI.dispatch(feedActions.changeFeedTab(feedTabQuery as FeedTabEnum));
      }
    } catch (e) {
      console.error('saveFeedTabFromUrl error, reason', e);
    }
  },
);

export const changeFeedChildGenres = createAsyncThunk<
void, {
  targetGenre: FeedGenreType;
  parentGenre: FeedGenreType;
},
{ state: RootState }
>(
  `${feedSliceName}/changeFeedChildGenres`,
  ({ parentGenre, targetGenre }, thunkAPI) => {
    const { genres } = feedSelector(thunkAPI.getState());

    thunkAPI.dispatch(changeFeedGenres(
      {
        genres: genres.map((genre) => {
          if (genre.name === parentGenre.name) {
            const newGenre = {
              ...genre,
              childs: genre.childs.map((childGenre) => (childGenre.name === targetGenre.name
                ? { ...childGenre, isActive: !childGenre.isActive }
                : childGenre)),
            };
            return newGenre;
          }

          return genre;
        }),
        targetGenre: parentGenre,
      },
    ));
  },
);

export type BannerType = { id: string; image: string; imageMob: string; url: string; alt: string; place: 'top' | 'right' };

export const getFeedRandomBanners = createAsyncThunk<
void,
void,
{ state: RootState }
>(
  '/getRandomBanners',
  async (_, thunkAPI) => {
    const bannersFromStorage = await axios.get<BannerType[]>(
      environments.isProduction
        ? 'https://storage.bookriver.ru/storage/banners/banners.json'
        : 'https://395010.selcdn.ru/bookriverru_devstorage/storage/banners/banners.json',
    );
    let mainBanners = bannersFromStorage.data.filter((banner) => banner.place === 'top');
    const randomMainBanners = [];
    let sidebarBanners = bannersFromStorage.data.filter((banner) => banner.place === 'right');
    const randomSidebarBanners = [];

    while (mainBanners.length > 0) {
      const rand = Math.floor(Math.random() * mainBanners.length);
      if (mainBanners[rand]) randomMainBanners.push(mainBanners[rand]);
      // eslint-disable-next-line
      mainBanners = mainBanners.filter((banner) => banner.url !== mainBanners[rand].url);
    }

    let sidebarBannersCount = 2;

    while (sidebarBannersCount > 0) {
      const rand = Math.floor(Math.random() * sidebarBanners.length);
      if (sidebarBanners[rand]) randomSidebarBanners.push(sidebarBanners[rand]);
      // eslint-disable-next-line
      sidebarBanners = sidebarBanners.filter((banner) => banner.url !== sidebarBanners[rand].url);
      sidebarBannersCount -= 1;
    }

    thunkAPI.dispatch(feedActions.changeRandomMainBanners(randomMainBanners));
    thunkAPI.dispatch(feedActions.changeRandomSidebarBanners(randomSidebarBanners));
  },
);

export const changeFeedGenres = createAsyncThunk<
void,
{ genres: FeedGenreType[], targetGenre?: FeedGenreType },
{ state: RootState }
>(
  `${feedSliceName}/changeFeedGenres`,
  ({ genres, targetGenre }, thunkAPI) => {
    try {
      const { isGedFeedLoading } = feedSelector(thunkAPI.getState());

      if (!isGedFeedLoading) {
        thunkAPI.dispatch(feedActions.changeGenres(genres.map((genre) => {
          const isAllSubgenresActive = genre.childs
            .every((childGenre) => childGenre.isActive);
          const isAllSubgenresInactive = genre.childs
            .every((childGenre) => !childGenre.isActive);

          const newGenre = {
            ...genre,
            isActive: genre.childs.length ? isAllSubgenresActive : genre.isActive,
            isAllSubgenresActive,
            isAllSubgenresInactive,
          };

          if (targetGenre?.name === genre.name) {
            thunkAPI.dispatch(feedActions.changeEditGenre(newGenre));
          }

          return newGenre;
        })));
      } else {
        return thunkAPI.rejectWithValue(
          { error: 'cannot edit genres when isGedFeedLoading === true' },
        );
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({ error: e.message });
    }
  },
);

export const checkMainPageConfirmation = createAsyncThunk(
  `${feedSliceName}/checkMainPageConfirmation`,
  async (_, thunkAPI) => {
    const token = Router.query.token as string;
    const action = Router.query.action as string;

    if (token && action) {
      const parsedLocation = qs.parseUrl(String(window.location));
      parsedLocation.query.token = undefined;
      parsedLocation.query.action = undefined;
      const url = new URL(qs.stringifyUrl(parsedLocation));
      const redirectUrl = url.pathname + url.search + url.hash;

      if (token && action === 'confirmRegistration') {
        thunkAPI.dispatch(emailConfirmation({ confirmationToken: token }));
        await Router.replace(Router.pathname, redirectUrl, { shallow: true });
      }
      if (token && action === 'confirmEmail') {
        thunkAPI.dispatch(confirmRegistration({ token }));
        await Router.replace(Router.pathname, redirectUrl, { shallow: true });
      }
      if (token && action === 'unsubscribeFromEmailDigests') {
        thunkAPI.dispatch(emailDigestUnsubscribe({ token }));
        await Router.replace(Router.pathname, redirectUrl, { shallow: true });
      }
      if (token && action === 'unsubscribeFromEmailDiscountNotifications') {
        thunkAPI.dispatch(emailDiscountUnsubscribe({ token }));
        await Router.replace(Router.pathname, redirectUrl, { shallow: true });
      }
    }
  },
);
